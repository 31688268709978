import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";
import withPreview from "../../utility/with-preview";

const BoxOfferta = ({ box }) => {
  const ctaLink =
    box.content?.boxContent?.content?.testoCta?.content?.ctaLinkPage?.value &&
    box.content?.boxContent?.content?.testoCta?.content?.ctaLinkPage?.value !== "#"
      ? box.content?.boxContent?.content?.testoCta?.content?.ctaLinkPage?.value
      : box.content?.boxContent?.content?.testoCta?.content?.ctaLinkAnchor?.value;

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div className="row my-4">
      <div className="col-12">
        <div
          ref={ref}
          className={classNames(
            "caratteristiche-offerta__box",
            box.content?.textPosition?.value &&
              `caratteristiche-offerta__box-${box.content.textPosition.value}`,
            inView && "caratteristiche-offerta__box--inview"
          )}
        >
          {box.content?.boxImage?.node && (
            <GatsbyImage
              className="caratteristiche-offerta__img"
              image={getImage(box.content.boxImage.node)}
              alt="alt"
            />
          )}
          <div
            className={classNames(
              "caratteristiche-offerta__textbox",
              box.content?.textPosition?.value
                ? `caratteristiche-offerta__textbox-${box.content.textPosition.value}`
                : ""
            )}
          >
            <div className="caratteristiche-offerta__title">{box.value}</div>
            <div
              className="caratteristiche-offerta__text"
              dangerouslySetInnerHTML={{ __html: box.content?.boxContent?.value }}
            ></div>
            {box.content?.boxContent?.content?.testoCta && ctaLink && (
              <div className="caratteristiche-offerta__cta mt-1">
                <a
                  className="caratteristiche-offerta__text caratteristiche-offerta__link"
                  href={ctaLink}
                >
                  {box.content.boxContent.content.testoCta.value}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CaratteristicheOfferta = ({ data }) => {
  const { title, boxTitle } = data;
  return (
    <section className="section caratteristiche-offerta wrapper-container">
      <div className="container-fluid">
        {title?.value && (
          <div className="row">
            <div
              className="col-12 d-md-none section__title text-center"
              dangerouslySetInnerHTML={{ __html: title.value }}
            ></div>
          </div>
        )}
        {boxTitle.map((box, i) => (
          <BoxOfferta box={box} key={i} />
        ))}
      </div>
    </section>
  );
};

export default withPreview(CaratteristicheOfferta);
export const fragment = graphql`
  fragment CaratteristicheOffertaFragment on LiferayCaratteristicheOfferta {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    boxTitle {
      value
      content {
        boxContent {
          value
          content {
            testoCta {
              value
              content {
                ctaLinkPage {
                  value
                }
                ctaLinkAnchor {
                  value
                }
              }
            }
          }
        }
        boxImage {
          node {
            gatsbyImageData(width: 1440)
          }
        }
        textPosition {
          value
        }
      }
    }
  }
`;
