import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import parse from "html-react-parser";
import ReactTooltip from "react-tooltip";
import { Link } from "../link";
import NavTabs from "../elements/nav-tabs";
import withPreview from "../../utility/with-preview";
import Icon from "../elements/icon-svg";

const optScript = {
  replace: ({ type, children }) => {
    if (type === "script") {
      const content = children?.[0]?.data;
      return (
        <span
          value={content.slice(content.indexOf(".tooltip({content:") + 19, content.indexOf('"})'))}
        />
      );
    }
    return <></>;
  },
};

const Addons = ({ addons, plus }) => {
  return addons?.map((item, i) => (
    <div className="row" key={i}>
      <div className="col-3 pr-0 d-flex align-items-start">
        <div className="d-flex align-items-center">
          {item.content?.addonImage?.node && (
            <>
              {plus && (
                <div className="card-slide__icon">
                  <Icon name="plus" cls="pr-2" />
                </div>
              )}
              <div>
                {item.content?.addonImage?.node && (
                  <GatsbyImage
                    image={getImage(item.content.addonImage.node)}
                    imgStyle={{
                      maxWidth: item.content.addonImage.node.probeInfo?.width,
                    }}
                    alt="Engie"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="col-9 pl-1">
        <div className="container">
          <div className="row">
            <div className="card-slide__info-title mx-0 text-left">
              {item.content?.addonTitle?.value}
            </div>
            <div
              className="card-slide__info-descr"
              dangerouslySetInnerHTML={{ __html: item.content?.addonDesc?.value }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  ));
};

const ComparatoreCard = ({ card, selected, model, numberOfCard }) => {
  const [showOption, setShowOption] = useState(false);
  const toggleOption = useCallback(() => setShowOption((value) => !value), []);

  const opt = {
    replace: ({ type, name, children }) => {
      if (
        name === "sup" &&
        children.find((child) => child?.attribs?.id?.startsWith("price-tooltip-card"))
      ) {
        const a = children.find((child) => child?.attribs?.id.startsWith("price-tooltip-card"));
        const id = a.attribs.id.slice(a.attribs.id.lastIndexOf("-") + 1);
        const tooltipImg = a?.children?.find(
          (child) => child?.name === "img" && child?.attribs?.class?.includes("tooltip")
        )?.attribs?.src;
        const tooltipText = parse(card.content.priceText.value, optScript).find(
          (elem) => elem.type === "span"
        ).props.value;
        return (
          <button className="card-slide__tooltip" data-tip={tooltipText} data-for={id}>
            <img
              className="card-slide__tooltip-icon ml-1 d-none d-md-block"
              src={tooltipImg}
              alt="icona"
            />
            <ReactTooltip id={id} className="tooltip" place="bottom" type="light" effect="float" />
          </button>
        );
      }
      if (type === "script") {
        return <></>;
      }
    },
  };

  const triggerPacchetto = useCallback((e) => {
    const nomePacchetto = e.target?.getAttribute("data-pacchetto");
    if (nomePacchetto) {
      const pacchettoEvent = new CustomEvent("set-nome-pacchetto", {
        detail: {
          nomePacchetto,
        },
      });
      document.dispatchEvent(pacchettoEvent);
    }
  }, []);

  return (
    <div
      className={classNames("col-lg-5 col-xl-4 d-lg-block", {
        "d-none": !selected,
      })}
    >
      <div
        className={classNames("card-slide", {
          "card-slide--option": showOption,
          "card-slide--modello-ab": model === "Comparatore Slide A/B",
        })}
      >
        <div
          className="card-slide__title"
          style={{ color: card.content?.offertaLabelColor?.value }}
          dangerouslySetInnerHTML={{ __html: card.content?.offertaLabel?.value }}
        ></div>
        <div
          className={classNames("card-slide__body mx-3 flex-column align-items-center", {
            "d-none": showOption,
            "d-flex": !showOption,
          })}
        >
          <div
            className={classNames("card-slide__info", {
              "card-slide__info--sep": !!card.content?.separatoreDettAgg?.value,
            })}
          >
            {card.content?.info?.map((info, i) => (
              <div className="row" key={i}>
                <div className="col-3 pt-1">
                  {info.content?.infoImage?.node && (
                    <GatsbyImage
                      imgStyle={{
                        maxWidth: info.content?.infoImage.node?.probeInfo?.width,
                      }}
                      image={getImage(info.content.infoImage.node)}
                      alt="Engie"
                    />
                  )}
                </div>
                <div className="col-9 pl-0">
                  <div className="row">
                    <div className="col-12">
                      <span className="d-flex align-items-center">
                        {info.content?.infoCheck?.value && (
                          <Icon name="check" cls="card-slide__check mr-1" />
                        )}
                        <div
                          className="card-slide__info-title"
                          dangerouslySetInnerHTML={{
                            __html: info.content?.infoTitle?.value,
                          }}
                        ></div>
                      </span>
                    </div>
                    <div className="col-12">
                      <div
                        className="card-slide__info-descr card-slide__info-descr--minh"
                        dangerouslySetInnerHTML={{ __html: info.content?.infoDesc?.value }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center d-inline-block px-1 mb-2">
            {card.content?.detailText?.length > 0 && (
              <div className="mx-2 mb-2">
                <span className="card-slide__info-title">{card.content?.titoloDettAgg?.value}</span>
              </div>
            )}
            {card.content?.detailText?.map((detail, i) => (
              <div className="card-slide__info-descr mx-2" key={i}>
                {detail?.value && <Icon name="check" cls="card-slide__check mr-1" />}
                {/*detail.value*/}
                <span dangerouslySetInnerHTML={{ __html: detail?.value }}></span>
              </div>
            ))}
          </div>
        </div>
        <div
          className={classNames({
            "mt-auto": !showOption && model === "Comparatore Slide new",
            "mt-2 mt-sm-auto": model === "Comparatore Slide A/B",
          })}
        >
          {card.content?.priceText?.value && (
            <div
              className={classNames("pl-1 pl-md-4 px-2 mt-auto mb-3", {
                "d-none": showOption,
              })}
            >
              {parse(card.content.priceText.value, opt)}
            </div>
          )}
          {card.content?.optionsBtn?.value &&
            (model === "Comparatore Slide new" ? (
              <button
                className={classNames("card-slide__options-btn d-flex align-items-center", {
                  active: showOption,
                })}
                onClick={toggleOption}
              >
                <span className="card-slide__info-title">{card.content.optionsBtn.value}</span>
                <Icon name="expand-more" cls="material-icons ml-auto" />
              </button>
            ) : (
              card.content?.titleOpz?.value &&
              card.content?.addons?.length > 0 && (
                <div
                  className={classNames("card-slide__options-btn text-center", {
                    "card-slide__options-btn--no-min-height": numberOfCard === 1,
                  })}
                >
                  <span className="card-slide__info-title">{card.content.titleOpz.value}</span>

                  <div className="container mt-4">
                    <Addons addons={card.content?.addons} />
                  </div>
                </div>
              )
            ))}
          <div
            className={classNames("container", {
              "d-none": !showOption,
            })}
          >
            {card.content?.titleOpz?.value && (
              <div className="row text-center mt-4 py-4">
                <div className="col-12">
                  <div className="card-slide__option-title">{card.content.titleOpz.value}</div>
                </div>
              </div>
            )}
            <Addons addons={card.content?.addons} plus={true} />
          </div>
        </div>
        {card.content?.btnText?.value &&
          card.content?.btnText?.content?.btnLink?.value &&
          card.content.btnText.content.btnLink.value !== "#" && (
            <div
              className={classNames("d-flex justify-content-center", {
                "mt-3": !showOption,
                "mt-auto": showOption,
              })}
            >
              <Link
                to={card.content.btnText.content.btnLink.value}
                title={card.content.btnText.value}
                className="cta-link btn-color-magenta"
                data-pacchetto={card.content?.offertaLabel?.value?.replace(/<[^>]+>/g, "")}
                onClick={triggerPacchetto}
              >
                {card.content.btnText.value}
              </Link>
            </div>
          )}
      </div>
    </div>
  );
};

const ComparatoreSlideNew = ({ data }) => {
  const { model, titoloComparatore, offertaTitle } = data;
  const modello = model?.value?.[0] || "Comparatore Slide new";
  const [selectedCard, setSelectedCard] = useState(0);
  return (
    <section
      className={classNames(
        "section wrapper-container comparatore-slide-new",
        modello === "Comparatore Slide A/B" && "comparatore-slide-new--modello-ab"
      )}
    >
      <div className="container-fluid">
        {titoloComparatore?.value && (
          <div className="row">
            <div className="col">
              <h2
                className={classNames("section__title", {
                  "text-left":
                    titoloComparatore.content?.titlePosition?.value?.[0] === "align-left",
                  "text-center":
                    titoloComparatore.content?.titlePosition?.value?.[0] === "align-center" ||
                    !titoloComparatore.content?.titlePosition?.value?.length,
                  "text-right":
                    titoloComparatore.content?.titlePosition?.value?.[0] === "align-right",
                })}
                style={{ color: titoloComparatore.content?.titleColor?.value }}
              >
                {titoloComparatore.value}
              </h2>
            </div>
          </div>
        )}
        {offertaTitle?.length > 1 && (
          <div className="comparatore-slide-new__nav-container row d-md-none">
            <div className="col-12 mb-2">
              <NavTabs
                tabs={offertaTitle}
                selectedTab={selectedCard}
                setTab={setSelectedCard}
                className="align-content-md-stretch flex-md-wrap"
              />
            </div>
          </div>
        )}
        <div className="row justify-content-center">
          {offertaTitle.slice(0, 2).map((card, i) => (
            <ComparatoreCard
              card={card}
              selected={selectedCard === i}
              key={i}
              model={modello}
              numberOfCard={offertaTitle?.length}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default withPreview(ComparatoreSlideNew);
export const fragment = graphql`
  fragment ComparatoreSlideNewFragment on LiferayComparatoreSlideNew {
    liferayFields {
      siteId
      articleId
    }
    model {
      value
    }
    titoloComparatore {
      value
      content {
        titleColor {
          value
        }
        titlePosition {
          value
        }
      }
    }
    offertaTitle {
      value
      content {
        offertaLabel {
          value
          content {
            offertaLabelColor {
              value
            }
          }
        }
        info {
          content {
            infoImage {
              node {
                gatsbyImageData(formats: PNG, width: 80)
                probeInfo {
                  width
                }
              }
            }
            infoTitle {
              value
            }
            infoDesc {
              value
            }
            infoCheck {
              value
            }
          }
        }
        separatoreDettAgg {
          value
        }
        titoloDettAgg {
          value
        }
        detailText {
          value
        }
        priceText {
          value
        }
        optionsBtn {
          value
        }
        titleOpz {
          value
        }
        addons {
          content {
            addonImage {
              node {
                gatsbyImageData(formats: PNG, width: 75)
                probeInfo {
                  width
                }
              }
            }
            addonTitle {
              value
            }
            addonDesc {
              value
            }
            addonLinkText {
              value
              content {
                addonLink {
                  value
                }
              }
            }
          }
        }
        btnText {
          value
          content {
            btnLink {
              value
            }
          }
        }
      }
    }
  }
`;
