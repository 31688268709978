import React from "react";
import { graphql } from "gatsby";
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";

const settings = {
  infinite: false,
  slidesToShow: 1,
  adaptiveHeight: false,
  responsive: [
    {
      breakpoint: 9999,
      settings: "unslick",
    },
    {
      breakpoint: 768,
      settings: {
        speed: 500,
        swipe: true,
        dots: true,
        arrows: false,
        centerPadding: "60px",
      },
    },
  ],
};

// labelBottone + immagini presenti -> articleId: 4667137
// no labelBotton + immagini mancanti -> articleId: 6475623
const BoxOpinioni = ({ data }) => {
  const { titoloPrincipale, immagineUtente, labelBottone } = data;

  return (
    <section className="section box-opinioni wrapper-container">
      <div className="container">
        {titoloPrincipale?.value && (
          <div className="row mb-2">
            <div className="col-12">
              <h1
                className="section__title box-opinioni__title text-center"
                dangerouslySetInnerHTML={{ __html: titoloPrincipale.value }}
              ></h1>
            </div>
          </div>
        )}
        <div className="row mb-5">
          <div className="col-12">
            <Slider {...settings} className="box-opinioni__slider d-md-flex">
              {immagineUtente?.map((opinione, i) => (
                <div className="box-opinioni__slide mb-5 mb-md-0" key={i}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4 box-opinioni__imgContainer text-center px-3 mb-2 mb-md-0">
                        {opinione?.node && (
                          <GatsbyImage
                            className="box-opinioni__userImg"
                            image={getImage(opinione.node)}
                            alt={opinione?.content?.nomeUtente?.value || "Immagine utente"}
                          />
                        )}
                      </div>
                      <div className="col-md-8 box-opinioni__opinioneBox px-0">
                        {opinione?.content?.nomeUtente?.value}
                        {opinione?.content?.recensione?.value && (
                          <div className="box-opinioni__recensione">
                            {opinione.content.recensione.value}
                          </div>
                        )}
                        <div className="box-opinioni__stars mt-3">
                          {opinione?.content?.immagineValutazione?.node && (
                            <GatsbyImage
                              className="box-opinioni__starsImg"
                              image={getImage(opinione.content.immagineValutazione.node)}
                              alt="Valutazione"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {labelBottone?.value && labelBottone?.content?.btnLink?.value && (
          <div className="row">
            <div className="col-12 text-center">
              <Link
                to={labelBottone.content.btnLink.value}
                title={labelBottone.value}
                className="cta-link"
              >
                {labelBottone.value}
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default withPreview(BoxOpinioni);
export const fragment = graphql`
  fragment BoxOpinioniFragment on LiferayBoxOpinioni {
    liferayFields {
      siteId
      articleId
    }
    titoloPrincipale {
      value
    }
    immagineUtente {
      node {
        gatsbyImageData(width: 130)
      }
      content {
        nomeUtente {
          value
        }
        recensione {
          value
        }
        immagineValutazione {
          node {
            gatsbyImageData(width: 125)
          }
        }
      }
    }
    labelBottone {
      value
      content {
        btnLink {
          value
        }
      }
    }
  }
`;
