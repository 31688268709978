import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import Slider from "react-slick";
import withPreview from "../../utility/with-preview";

const settings = {
  className: "center",
  centerMode: true,
  centerPadding: "40px",
  infinite: false,
  slidesToShow: 1,
  speed: 500,
  swipe: true,
  dots: true,
  arrows: false,
  adaptiveHeight: false,
};

const AssicurazioneWidget = ({ data }) => {
  const { title } = data;
  const [activeTab, setActiveTab] = useState(0);
  return (
    <section className="section assicurazione-widget wrapper-container px-0">
      <div className="container-fluid">
        {title?.value && (
          <div className="row">
            <div
              className="col-12 section__title text-center"
              dangerouslySetInnerHTML={{ __html: title.value }}
            ></div>
          </div>
        )}
        <div className="row d-none d-md-block">
          <div className="col-12 assicurazione-widget__tabs d-none d-sm-flex justify-content-around no-wrap">
            {title?.content?.tabTitle.map((tab, i) => (
              <div
                className={classNames(
                  "assicurazione-widget__tab",
                  activeTab === i && "assicurazione-widget__tab--active",
                  i !== 0 && "ml-1",
                  i !== title.content.tabTitle.length - 1 && "mr-1"
                )}
                key={i}
              >
                <Link
                  to={`#tab-${i}`}
                  className={classNames(
                    "assicurazione-widget__btn d-flex align-items-center justify-content-center w-100",
                    activeTab === i && "assicurazione-widget__btn--active"
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(i);
                  }}
                >
                  <span
                    className={classNames(
                      "assicurazione-widget__iconContainer",
                      activeTab === i && "assicurazione-widget__iconContainer--active"
                    )}
                  >
                    {tab.content?.tabIconOn?.node && (
                      <GatsbyImage
                        className="icon icon--active"
                        image={getImage(tab.content.tabIconOn.node)}
                        alt="icon"
                      />
                    )}
                    {tab.content?.tabIconOff?.node && (
                      <GatsbyImage
                        className="icon icon--inactive"
                        image={getImage(tab.content.tabIconOff.node)}
                        alt="icon"
                      />
                    )}
                  </span>
                  <span
                    className={classNames(
                      "assicurazione-widget__tabLabel ml-2 d-none d-lg-block",
                      activeTab === i && "assicurazione-widget__tabLabel--active"
                    )}
                  >
                    {tab.value}
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="row d-none d-md-block">
          <div className="col-12">
            {title?.content?.tabTitle.map((tab, i) => (
              <div
                className={classNames(
                  "assicurazione-widget__contentBox container-fluid",
                  activeTab !== i && "d-none"
                )}
                key={i}
              >
                <div className="row">
                  <div className="col-md-7 assicurazione-widget__textBox">
                    {tab?.content?.tabTitleBlock?.value && (
                      <div
                        className="assicurazione-widget__textTitle"
                        dangerouslySetInnerHTML={{ __html: tab.content.tabTitleBlock.value }}
                      ></div>
                    )}
                    {tab?.content?.tabDescription?.value && (
                      <div
                        className="assicurazione-widget__textContent mt-4"
                        dangerouslySetInnerHTML={{ __html: tab.content.tabDescription.value }}
                      ></div>
                    )}
                    {tab?.content?.tabLink?.value &&
                      tab?.content?.tabLink?.content?.tabLinkDestination?.value && (
                        <div className="assicurazione-widget__textLink my-4">
                          <a href={tab.content.tabLink.content.tabLinkDestination.value}>
                            {tab.content.tabLink.value}
                          </a>
                        </div>
                      )}
                  </div>
                  <div className="col-md-5 align-center">
                    {tab?.content?.tabImage?.node && (
                      <GatsbyImage
                        className="assicurazione-widget__img"
                        image={getImage(tab.content.tabImage.node)}
                        alt={tab.value}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row d-md-none">
          <div className="col-12 px-0">
            <Slider {...settings} className="assicurazione-widget__slider py-2">
              {title?.content?.tabTitle.map((tab, i) => (
                <div key={i} className="assicurazione-widget__slide d-flex flex-column">
                  <div className="assicurazione-widget__slideTitle d-flex align-items-center p-3">
                    <span className="assicurazione-widget__iconContainer">
                      {tab.content?.tabIconOff?.node && (
                        <GatsbyImage
                          className="icon"
                          image={getImage(tab.content.tabIconOff.node)}
                          alt="icon"
                        />
                      )}
                    </span>
                    <span className="assicurazione-widget__tabLabel ml-2">{tab.value}</span>
                  </div>
                  {tab?.content?.tabDescription?.value && (
                    <div
                      className="assicurazione-widget__textContent flex-fill mx-4 pt-4 px-3 pb-3"
                      dangerouslySetInnerHTML={{ __html: tab?.content?.tabDescription?.value }}
                    ></div>
                  )}
                  {tab?.content?.tabLink?.value &&
                    tab?.content?.tabLink?.content?.tabLinkDestination?.value && (
                      <div className="assicurazione-widget__textLink mt-auto mb-4 text-center">
                        <a href={tab.content.tabLink.content.tabLinkDestination.value}>
                          {tab.content.tabLink.value}
                        </a>
                      </div>
                    )}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(AssicurazioneWidget);
export const fragment = graphql`
  fragment AssicurazioneWidgetFragment on LiferayAssicurazioneWidget {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
      content {
        tabTitle {
          value
          content {
            tabIconOff {
              node {
                gatsbyImageData(width: 48, layout: FIXED)
              }
            }
            tabIconOn {
              node {
                gatsbyImageData(width: 48, layout: FIXED)
              }
            }
            tabImage {
              node {
                gatsbyImageData(width: 900)
              }
            }
            tabTitleBlock {
              value
            }
            tabDescription {
              value
            }
            tabLink {
              value
              content {
                tabLinkDestination {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;
