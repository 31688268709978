import React, { useState, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse, { domToReact, attributesToProps } from "html-react-parser";
import ReactTooltip from "react-tooltip";
import infoIcon from "../../images/info_icon.png";
import NetReviewStars from "../elements/netreviews-stars";
import withPreview from "../../utility/with-preview";

const TabBox = ({ data }) => {
  const { description, boxContent, activeTab, showProductStars, prodottoCRM /* showText */ } = data;
  const [active, setActive] = useState(activeTab?.value?.[0]);
  const tooltips = boxContent?.map((box) => box?.content?.priceTooltip?.value);
  const [maxTabHeight, setMaxTabHeight] = useState();

  useEffect(() => {
    const tabs = document.getElementsByClassName("tab-box__tab");
    setMaxTabHeight(Math.max(...Array.from(tabs).map((tab) => tab.getBoundingClientRect().height)));
  }, []);

  const boxContentValues = useMemo(
    () =>
      boxContent?.map((boxContent) =>
        boxContent?.value
          ?.replace(/@corrispettivoEnergia/g, prodottoCRM?.corrispettivoEnergia)
          .replace(/@componentePrezzoFisso/g, prodottoCRM?.componentePrezzoFisso)
          .replace(/@corrispettivoF1/g, prodottoCRM?.corrispettivoF1)
          .replace(/@corrispettivoF2/g, prodottoCRM?.corrispettivoF2)
          .replace(/@corrispettivoF3/g, prodottoCRM?.corrispettivoF3)
          .replace(/@quotaFissaLuce/g, prodottoCRM?.quotaFissaLuce)
          .replace(/@quotaFissaGas/g, prodottoCRM?.quotaFissaGas)
          .replace(/@quotaVariabileLuce/g, prodottoCRM?.quotaVariabileLuce)
          .replace(/@quotaVariabileGas/g, prodottoCRM?.quotaVariabileGas)
          .replace(/@quotaAnnualeLuceFissa/g, prodottoCRM?.quotaFissaLuceAnnuale)
          .replace(/@quotaAnnualeGasFissa/g, prodottoCRM?.quotaFissaGasAnnuale)
          .replace(/@spreadF1/g, prodottoCRM?.spreadF1)
          .replace(/@spreadF2/g, prodottoCRM?.spreadF2)
          .replace(/@spreadF3/g, prodottoCRM?.spreadF3)
          .replace(/@corrispettivoBiorariaF1/g, prodottoCRM?.corrispettivoBiorariaF1)
          .replace(/@corrispettivoBiorariaF23/g, prodottoCRM?.corrispettivoBiorariaF23)
          .replace(/@quotaCommFissaDomesticoGas/g, prodottoCRM?.quotaCommFissaDomesticoGas)
          .replace(/@quotaCommFissaDomesticoLuce/g, prodottoCRM?.quotaCommFissaDomesticoLuce)
          .replace(/@quotaCommVariabileDomesticoGas/g, prodottoCRM?.quotaCommVariabileDomesticoGas)
          .replace(
            /@quotaCommVariabileDomesticoLuce/g,
            prodottoCRM?.quotaCommVariabileDomesticoLuce
          )
      ),
    [boxContent, prodottoCRM]
  );

  const opt = (i) => {
    return {
      replace: ({ name, children, attribs }) => {
        if (name === "p" && children.find((child) => child?.attribs?.class === "price-tooltip")) {
          return (
            <div>
              {domToReact(children, {
                replace: ({ attribs }) => {
                  if (attribs?.class && attribs.class === "price-tooltip") {
                    return (
                      <span className="tab-box__tooltip" data-tip={tooltips[i]}>
                        <span className="d-inline-flex">
                          <>
                            <img
                              className="tab-box__tooltip-icon ml-1 d-none d-md-block"
                              src={infoIcon}
                              alt="icona"
                            />
                            <ReactTooltip
                              className="tooltip"
                              place="bottom"
                              type="light"
                              effect="float"
                            />
                          </>
                        </span>
                      </span>
                    );
                  }
                },
              })}
            </div>
          );
        }
        if (name === "span" && attribs?.style?.includes("!important")) {
          const props = attributesToProps(attribs);
          props.style = Object.fromEntries(
            Object.entries(props.style).map(([property, value]) => [
              property,
              value.replace(/!important/g, "").trim(),
            ])
          );
          return <span {...props}>{domToReact(children, opt(i))}</span>;
        }
        if (name === "img" && !attribs?.alt) {
          return <img {...attributesToProps(attribs)} alt="" />;
        }
      },
    };
  };

  return (
    <section className="section tab-box wrapper-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 col-md-7 col-lg-8 d-flex flex-column justify-content-center">
            <div className="tab-box__text">
              {(description?.content?.htmlDescription?.value || description?.value) && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: description.content.htmlDescription.value || description.value,
                  }}
                />
              )}
              {description?.content?.descriptionSubtitle?.value && (
                <div
                  className="tab-box__text-sub mb-2"
                  dangerouslySetInnerHTML={{
                    __html: description.content.descriptionSubtitle.value,
                  }}
                />
              )}
              {showProductStars?.value && showProductStars?.content?.dataProductId?.value && (
                <NetReviewStars dataProductId={showProductStars.content.dataProductId.value} />
              )}
            </div>
          </div>
          <div className="col-sm-6 col-md-5 col-lg-4 d-flex justify-content-center d-sm-block">
            <div className="tab-box__tabElement">
              <div className="tab-box__tabLinks">
                {boxContent?.map((boxContent, i) => (
                  <button
                    className={classNames(
                      "tab-box__tabBtn",
                      (!boxContent.content?.customColor?.value ||
                        !boxContent.content?.customIconNoactive?.node ||
                        !boxContent.content?.customIconActive?.node) &&
                        `tab-box__tabBtn--${boxContent.content?.tab?.value?.[0]}`,
                      active === boxContent.content?.tab?.value?.[0] && "tab-box__tabBtn--active"
                    )}
                    key={boxContent.content?.tabId?.value || i}
                    value={boxContent.content?.tab?.value}
                    onClick={() => setActive(boxContent.content?.tab?.value[0])}
                    style={{
                      "--color": boxContent.content?.customColor?.value,
                      "--defaultImg":
                        !boxContent.content?.customIconActive?.node ||
                        !boxContent.content?.customIconNoactive?.node
                          ? null
                          : "defaultImg",
                    }}
                    aria-label={boxContent.content?.tab?.value[0]}
                  >
                    {boxContent.content?.customIconActive?.node &&
                      boxContent.content?.customIconNoactive?.node && (
                        <GatsbyImage
                          className="icon"
                          image={getImage(
                            active === boxContent.content?.tab?.value?.[0]
                              ? boxContent.content.customIconActive.node
                              : boxContent.content.customIconNoactive.node
                          )}
                          alt="icon"
                        />
                      )}
                  </button>
                ))}
              </div>
              <div className="tab-box__tabs">
                {boxContent.map((boxContent, i) => (
                  <div
                    className={classNames(
                      "tab-box__tab text-center",
                      `tab-box__tab--${boxContent.content?.tab?.value?.[0]}`,
                      active === boxContent.content?.tab?.value?.[0] && "tab-box__tab--active"
                    )}
                    style={{ height: maxTabHeight + "px" }}
                    key={i}
                    id={boxContent.content?.tab?.value}
                  >
                    <div className="tab-box__tab-content">{parse(boxContentValues[i], opt(i))}</div>
                    <div className="tab-box__tabDeadline">
                      {boxContent.content?.boxNote?.value
                        ? boxContent.content.boxNote.value
                        : prodottoCRM?.dataFineValidita &&
                          "Fino al " + prodottoCRM.dataFineValidita}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(TabBox);
export const fragment = graphql`
  fragment TabBoxFragment on LiferayTabBox {
    liferayFields {
      siteId
      articleId
    }
    description {
      value
      content {
        htmlDescription {
          value
        }
        descriptionSubtitle {
          value
        }
      }
    }
    boxContent {
      value
      content {
        priceTooltip {
          value
        }
        tab {
          value
        }
        customColor {
          value
        }
        customIconActive {
          node {
            gatsbyImageData(width: 48, layout: FIXED)
          }
        }
        customIconNoactive {
          node {
            gatsbyImageData(width: 48, layout: FIXED)
          }
        }
        boxNote {
          value
        }
        boxNote {
          value
        }
        tabId {
          value
        }
      }
    }
    activeTab {
      value
    }
    showProductStars {
      value
      content {
        dataProductId {
          value
        }
      }
    }
    showText {
      value
    }
    prodottoCRM {
      componentePrezzoFisso
      corrispettivoEnergia
      corrispettivoF1
      corrispettivoF2
      corrispettivoF3
      quotaFissaGas
      quotaFissaLuce
      quotaVariabileGas
      quotaVariabileLuce
      dataFineValidita
      quotaFissaLuceAnnuale
      quotaFissaGasAnnuale
      spreadF1
      spreadF2
      spreadF3
      corrispettivoBiorariaF1
      corrispettivoBiorariaF23
      quotaCommFissaDomesticoGas
      quotaCommFissaDomesticoLuce
      quotaCommVariabileDomesticoGas
      quotaCommVariabileDomesticoLuce
    }
  }
`;
