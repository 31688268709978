import React, { useMemo, useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import Slider from "react-slick";
import NavTabs from "../elements/nav-tabs";
import Icon from "../elements/icon-svg";
import ProdottoSlide from "../elements/prodotto-slide";
import withPreview from "../../utility/with-preview";

const ArrowBtn = ({ slideCount, currentSlide, ...props }) => (
  <button type="button" aria-label="Arrow" {...props}>
    <Icon name="chevron-circle-right" />
  </button>
);
const settings = {
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  dots: false,
  prevArrow: <ArrowBtn />,
  nextArrow: <ArrowBtn />,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "10px",
      },
    },
  ],
};

const OpzioniProdottoV2 = ({ data }) => {
  //TODO idUnivoco? customAdvId
  const {
    liferayFields: { articleId },
    mainTitle,
    etichetta1,
    etichetta2,
    etichetta3,
    imageBox,
    formId,
  } = data;
  const slidesEtichetta1 = useMemo(
    () =>
      etichetta1?.value &&
      imageBox
        ?.map((item) => item.content)
        .filter((item) => item?.productType?.value === "etichetta1"),
    [etichetta1, imageBox]
  );
  const slidesEtichetta2 = useMemo(
    () =>
      etichetta2?.value &&
      imageBox
        ?.map((item) => item.content)
        .filter((item) => item?.productType?.value === "etichetta2"),
    [etichetta2, imageBox]
  );
  const slidesEtichetta3 = useMemo(
    () =>
      etichetta3?.value &&
      imageBox
        ?.map((item) => item.content)
        .filter((item) => item?.productType?.value === "etichetta3"),
    [etichetta3, imageBox]
  );
  const sliders = [slidesEtichetta1, slidesEtichetta2, slidesEtichetta3].filter(Boolean);
  const tabs = useMemo(() => {
    const etichette = [etichetta1?.value, etichetta2?.value, etichetta3?.value].filter(Boolean);
    return etichette.map((item) => ({
      value: item,
    }));
  }, [etichetta1, etichetta2, etichetta3]);
  const [selectedCard, setSelectedCard] = useState(0);
  return (
    <section className="section opzioni-prodotto-v2 wrapper-container">
      <div className="container-fluid">
        {mainTitle?.value && (
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section__title"
                dangerouslySetInnerHTML={{ __html: mainTitle.value }}
              ></div>
            </div>
          </div>
        )}
        {tabs.length > 1 && (
          <div className="row">
            <div className="col-12 mb-2">
              <NavTabs
                tabs={tabs}
                selectedTab={selectedCard}
                setTab={setSelectedCard}
                className="align-content-md-stretch flex-md-wrap"
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 p-0">
            {sliders.map((slides, i) => (
              <Slider
                className={classNames("opzioni-prodotto", {
                  "d-none": i !== selectedCard,
                })}
                {...settings}
                key={i}
              >
                {slides.map((slide, j) => (
                  <div key={j}>
                    <ProdottoSlide
                      articleId={articleId}
                      i={i}
                      slide={slide}
                      j={j}
                      formId={formId}
                      slidesNumber={slides?.length}
                      inSlider={true}
                    />
                  </div>
                ))}
              </Slider>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(OpzioniProdottoV2);
export const fragment = graphql`
  fragment OpzioniProdottoV2Fragment on LiferayOpzioniProdottoV2 {
    liferayFields {
      siteId
      articleId
    }
    idUnivoco {
      value
    }
    mainTitle {
      value
    }
    etichetta1 {
      value
    }
    etichetta2 {
      value
    }
    etichetta3 {
      value
    }
    formId {
      value
    }
    imageBox {
      content {
        productType {
          value
        }
        tooltipEtichetta {
          value
        }
        tooltipTesto {
          value
        }
        colorBox {
          value
        }
        backgroundColorBox {
          value
        }
        radioLabel {
          value
        }
        labelBox {
          value
        }
        titleBox {
          value
        }
        descriptionBox {
          value
        }
        radioSection {
          value
        }
        lineBox {
          content {
            newSectionBox {
              value
              content {
                sectionDetail {
                  value
                }
                infoDetail {
                  value
                }
              }
            }
          }
        }
        radioPrice {
          value
        }
        priceBox {
          value
          content {
            priceDescription {
              value
            }
          }
        }
        radioButton {
          value
        }
        buttonBox {
          value
        }
        newPage {
          value
        }
        linkButton {
          value
        }
        customAdvId {
          value
        }
      }
    }
  }
`;
